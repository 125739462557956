<template>
  <div class="contact">
    <Nav></Nav>
    <div class="main">
      <div class="welcome">
        <img src="../assets/contact/画板 61.svg" alt="" />
      </div>
      <div class="email">
        <div class="hello">
          <a href="mailto:Hello@Followind.com">Hello@Followind.com</a>
          <img src="../assets/contact/画板 58.svg" alt="" />
          <div class="hr">
            <a href="mailto:Hr@Followind.com">Hr@Followind.com</a>
            <img src="../assets/contact/画板 59.svg" alt="" />
          </div>
        </div>
      </div>
      <ul class="about">
        <li>
          <div class="title">Tel</div>
          <div class="msg">+86 17666662287</div>
        </li>
        <li>
          <div class="title">WeChat</div>
          <div class="msg">MYseeee</div>
        </li>
        <li>
          <div class="title">商务合作</div>
          <div class="msg">Hello@Followind.com</div>
        </li>
        <li>
          <div class="title">工作机会</div>
          <div class="msg">Hr@Followind.com</div>
        </li>
        <li>
          <div class="title">Add</div>
          <div class="msg">
            11F, Vanke Cloud City, Longhe Erli, <br />
            Jimei District, Xiamen, China<br />
            中国厦门市集美区龙荷二里万科云城11F
          </div>
        </li>
      </ul>
      <div class="icons">
        <a href="https://www.behance.net/3462788053ae3" target="_blank"
          ><div class="con icon1"></div
        ></a>
        <a
          href="https://www.gtn9.com/user_show.aspx?id=A85868F47C8B4AEA"
          target="_blank"
          ><div class="con icon2"></div
        ></a>
        <a href="https://followind.zcool.com.cn" target="_blank"
          ><div class="con icon3"></div
        ></a>
        <a href="http://followind.gtn9.com" target="_blank"
          ><div class="con icon4"></div
        ></a>
          <div class="con icon5">
            <div class="icon6"></div>
          </div>
      </div>
    </div>
  </div>
</template>

<script>

import Nav from "../components/Nav";
export default {
  components: {
    Nav,
  },
  data() {
    return {};
  },
  methods: {

  }
};
</script>

<style scoped lang="less">
.contact {
  height: 110vh;
  position: relative;
  overflow: hidden;
  .main {
    top: 0;
    right: 100px;
    width: 500px;
    margin-top: 20vh;
    position: absolute;
    a {
      color: #000;
      text-decoration: none;
    }
    a:hover {
      color: #006d46;
      background-image: -webkit-linear-gradient(
        left,
        #006d46,
        #006d46,
        #00ff95
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      text-decoration: underline;
    }
    .email {
      position: absolute;
      top: 0;
      left: 0;
    }
    .hello,
    .hr {
      font-size: 44px;
    }
    .hello {
      img {
        top: 0;
        right: -60px;
        position: absolute;
        width: 70px;
      }
    }
    .hr {
      right: 0;
      position: absolute;
      img {
        top: 0;
        right: -35px;
        position: absolute;
        width: 45px;
      }
    }
    .welcome {
      display: none;
    }
    .about {
      position: absolute;
      left: 0;
      top: 180px;
      font-size: 14px;
      line-height: 28px;
      li {
        display: flex;
        margin-top: 0px;
        .title {
          width: 115px;
          font-weight: 500;
        }
      }
    }
    .icons {
      display: flex;
      justify-content: space-between;
      margin-top: 450px;
      width: 400px;
      .con {
        width: 45px;
        height: 45px;
        cursor: pointer;
      }
      .icon1 {
        background: url("../assets/contact/behance.svg") no-repeat;
      }
      .icon1:hover {
        background: url("../assets/contact/behance_hover.svg") no-repeat;
      }
      .icon2 {
        background: url("../assets/contact/gtn.svg") no-repeat;
      }
      .icon2:hover {
        background: url("../assets/contact/gtn_hover.svg") no-repeat;
      }
      .icon3 {
        background: url("../assets/contact/zcool.svg") no-repeat;
      }
      .icon3:hover {
        background: url("../assets/contact/zcool_hover.svg") no-repeat;
      }
      .icon4 {
        background: url("../assets/contact/instagram.svg") no-repeat;
      }
      .icon4:hover {
        background: url("../assets/contact/instagram_hover.svg") no-repeat;
      }
      .icon5 {
        position: relative;
        background: url("../assets/contact/wechat.svg") no-repeat;
      }
      .icon5:hover {
        background: url("../assets/contact/wechat_hover.svg") no-repeat;
      }
      .icon6 {
        position: absolute;
        bottom: 12px;
        right: -70px;
        width: 70px;
        height: 70px;
        opacity: 0;
        background: url("../assets/contact/画板\ 56.svg") no-repeat;
      }
      .icon5:hover {
        .icon6 {
          opacity: 1;
        }
      }
    }
  }
}

// /* 宽度小于870 */
@media only screen and (max-width: 870px) {
  .contact {
    .main {
      position: relative;
      padding-left: 20px;
      left: 0;
      top: -30px;
      .welcome {
        display: block;
        img {
          width: 250px;
        }
      }
      .hello{
        font-size: 33px;
              img {
        top: 0;
        right: -55px;
        width: 65px;
      }
        .hr{
          left: 0;
          font-size: 30px;
      img {
        top: 0;
        right: 35px;
        width: 40px;
      }
        }
      }
      .email {
        position: absolute;
        top: 130px;
        left: 20px;

      }
      .about{
        position: absolute;
        left: 20px;
        font-size: 13px;
        top: 250px;
      }
      .icons{
        width: 330px!important;
        margin-top: 400px;
      .con {  
          width: 40px;
          height: 35px;
          cursor: pointer;
        }
      }
    }
  }
}
</style>